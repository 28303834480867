import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The modern web is awesome! We can easily create accessible, robust modal dialogs with the
native `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` element. Want to open a menu or tooltip on top of the other page
content? No problem! The HTML attribute `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` turns any element into popover content.`}</p>
    <p>{`The last few months, I've been building more and more dialogs with the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog"
      }}>{`native HTML element`}</a>{`. And in April, when
the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{` reached cross-browser support,
I started experimenting with popover in various projects. I quickly realized: These web features make our lives
as web developers a lot easier, but there are some pitfalls. Especially when you combine both features!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEEAv/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAZcNBSDGf//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMEMRITIf/aAAgBAQABBQLHo8cAQ7pv6wzco2//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BgX//xAAWEQADAAAAAAAAAAAAAAAAAAAQESH/2gAIAQIBAT8BrH//xAAcEAAABgMAAAAAAAAAAAAAAAAAAhAREiEBcaH/2gAIAQEABj8Ckd9CnSJsOK6n/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIVGR/9oACAEBAAE/IQQC8ZHVdbQ6MMLcCPYDNavqZZP/2gAMAwEAAgADAAAAEA8P/8QAFxEBAAMAAAAAAAAAAAAAAAAAARAhMf/aAAgBAwEBPxAUrY//xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAECAQE/EAbXF//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQVFxYf/aAAgBAQABPxAXoteA6K9r5i4g4kFKPpxJ7k3N/cQ3wIKXaN5xgnsQafh/DeJUamrn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Several pancakes stacked on top of each other.",
          "title": "Several pancakes stacked on top of each other.",
          "src": "/static/0142204fa0ea08c8e382e41c9faa5b4e/e5166/pexels-rama-khandkar-pancakes.jpg",
          "srcSet": ["/static/0142204fa0ea08c8e382e41c9faa5b4e/f93b5/pexels-rama-khandkar-pancakes.jpg 300w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/b4294/pexels-rama-khandkar-pancakes.jpg 600w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/e5166/pexels-rama-khandkar-pancakes.jpg 1200w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/d9c39/pexels-rama-khandkar-pancakes.jpg 1800w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/df51d/pexels-rama-khandkar-pancakes.jpg 2400w", "/static/0142204fa0ea08c8e382e41c9faa5b4e/d2602/pexels-rama-khandkar-pancakes.jpg 4032w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Rama Khandkar / pexels.com`}</em></p>
    <p>{`I'll quickly go over the basics of how dialogs and popovers work together. Then I'll share some of my hard-earned
learnings with you. If you're not familiar with the features in general, I recommend you read my blog posts
`}<a parentName="p" {...{
        "href": "/native-dialog-element.en/"
      }}>{`“Why you should use the Native Dialog Element”`}</a>{` and
`}<a parentName="p" {...{
        "href": "/popover-api-accessibility.en/"
      }}>{`“Make your content pop with the Popover API and CSS Anchor Positioning”`}</a>{`.`}</p>
    <h2>{`The Basics: How dialog and popover elements interact`}</h2>
    <p>{`When you open a modal dialog with the `}<InlineCode mdxType="InlineCode">{`showModal()`}</InlineCode>{` method, the dialog is added to the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/Top_layer"
      }}>{`top layer`}</a>{` and rendered on top of other page content.
The same happens when you show popover content using the `}<InlineCode mdxType="InlineCode">{`showPopover()`}</InlineCode>{` method.`}</p>
    <p>{`Now, what happens if an element is already open in the top layer and you add another element to it? The elements are
stacked in the order they are added to the top layer. The last one in always appears on top. You can't use
the `}<InlineCode mdxType="InlineCode">{`z-index`}</InlineCode>{` property to change this stacking order. The only thing that matters is, when
an element was added to the top layer.`}</p>
    <p>{`To give you an example: A button opens a menu panel as a popover. This menu contains an option that opens a modal
dialog that is rendered on top of the menu panel. This dialog contains a button that opens a tooltip popover on top
of the dialog. We could go on and on. 😉`}</p>
    <h2>{`What you need to know about dialogs and popovers`}</h2>
    <h3>{`Animation as progressive enhancement`}</h3>
    <p>{`Everything looks better with smooth entry and exit animations. There's only one problem: Dialogs and popovers are set
to `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{` when hidden and `}<InlineCode mdxType="InlineCode">{`display: block`}</InlineCode>{` when shown. And we
all know that you can't transition content from or to `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{`. Right? Wrong!`}</p>
    <p>{`The new `}<InlineCode mdxType="InlineCode">{`@starting-style`}</InlineCode>{` rule together with the `}<InlineCode mdxType="InlineCode">{`transition-behavior`}</InlineCode>{`
CSS property enable us to animate dialogs and popovers. I described both features in detail in my blog post
`}<a parentName="p" {...{
        "href": "/accessible-popover-alert.en/"
      }}>{`“Accessible Alerts made easy by the Popover API”`}</a>{`.`}</p>
    <p>{`Check out my demo for a modal dialog with smooth fade in and out animation. At the moment of writing, this only works
in Chrome and Edge:`}</p>
    <iframe title="Dialog Animation Demo" src="https://codepen.io/alexlehner86/embed/jOoaZRK?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/jOoaZRK">
    Dialog Animation Demo</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`You can define transitions for the dialog (or popover) itself as well as its backdrop. Here's the CSS code for
animating the dialog:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`dialog {
    --duration: 150ms;
    --start-opacity: 0.5;
    --start-scale: scale(0.8);

    /* End values for fade out. */
    opacity: var(--start-opacity);
    transform: var(--start-scale);
    transition:
        opacity var(--duration) ease-out,
        transform var(--duration) cubic-bezier(0, 0, 0.2, 1),
        overlay var(--duration) allow-discrete,
        display var(--duration) allow-discrete;
}

dialog[open] {
    /* End values for fade in; start values for fade out. */
    opacity: 1;
    transform: scale(1);

    @starting-style {
        /* Start values vor fade in. */
        opacity: var(--start-opacity);
        transform: var(--start-scale);
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`And this is how you animate the dialog's backdrop:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* Styling for backdrop behind the dialog */
dialog::backdrop {
    background: rgb(0 0 0 / 0.32);
    /* End value for fade out. */
    opacity: 0;
    transition: opacity var(--duration),
        overlay var(--duration) allow-discrete,
        display var(--duration) allow-discrete;
}

dialog[open]::backdrop {
    /* End value for fade in; start value for fade out. */
    opacity: 1;
}

/* This starting-style rule cannot be nested inside the above selector because the nesting selector cannot represent pseudo-elements. */
@starting-style {
    dialog[open]::backdrop {
        /* Start value vor fade in. */
        opacity: 0;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`But what about browser support? Don't worry! The `}<InlineCode mdxType="InlineCode">{`@starting-style`}</InlineCode>{` rule and
the `}<InlineCode mdxType="InlineCode">{`transition-behavior`}</InlineCode>{` property are perfect examples of progressive enhancement. If a browser
doesn't support these new features, then it will still render the dialog or popover element, but without the animation.`}</p>
    <h3>{`Automatically close the dialog on backdrop click`}</h3>
    <p>{`The native `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` element has several great features built in:`}</p>
    <ul>
      <li parentName="ul">{`When a modal dialog is opened, the browser moves focus to the first interactive element inside of the dialog.`}</li>
      <li parentName="ul">{`Closing the modal dialog returns focus to the element that opened the dialog.`}</li>
      <li parentName="ul">{`Users can close the modal dialog with the `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{` key.`}</li>
    </ul>
    <p>{`But one important feature is not supported by default: Automatically closing the dialog when the user clicks on the
backdrop. In my `}<a parentName="p" {...{
        "href": "/native-dialog-element.en/"
      }}>{`first blog post about dialogs`}</a>{`, I demonstrated a custom implementation
of this feature: Getting the coordinates of the click and comparing them to the dialog's rectangle.`}</p>
    <p>{`Some time ago, I came across a more elegant solution: You add
a `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener"
      }}>{`click event listener`}</a>{` to your dialog
and then check the tag name of the event target. Here's the JavaScript code for the function:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`function onDialogClick(event) {
    event.stopPropagation();
    if (event.target.tagName === "DIALOG") {
        dialogElementRef.close();
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`A click on the dialog's backdrop is registered as a click on the dialog element. For this to work, the dialog's content
needs to be wrapped in an extra element. Otherwise, clicking inside certain areas in the dialog would also close it.`}</p>
    <p>{`Check out the CodePen demo above where I also implemented this custom behavior.`}</p>
    <h3>{`How to properly nest popover content in modal dialogs`}</h3>
    <p>{`Some time ago, I was working on a web project for a client that includes a list of items. The user can click on one of
the items to open a modal dialog with more details. This dialog contains a button that allows the user to add the item
to their list of favorites. Afterwards, a `}<a parentName="p" {...{
        "href": "https://m3.material.io/components/snackbar/overview"
      }}>{`snackbar`}</a>{`
appears on the bottom of the screen that also includes an undo button.`}</p>
    <p>{`Being the hip, state-of-the-art web developer that I am, I wanted to use the `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` element
combined with a `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` element for the snackbar. After implementing everything, I started to
test the undo feature and was baffled: Although the snackbar with the undo button was visible and appeared on top of the
modal dialog, I wasn't able to interact with it. Me: `}<ItalicText mdxType="ItalicText">{`“What the hell is happening?!”`}</ItalicText></p>
    <p>{`After some research, I found the cause of the problem. But before I tell you, check out this demo with a minimal version of
the web project I described above. The dialog includes two buttons: The first opens a snackbar that you can't interact with.
The second button opens a snackbar with a working undo button. Can you tell the difference?`}</p>
    <iframe title="Dialog with Popover Demo" src="https://codepen.io/alexlehner86/embed/mdYqZdW?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/mdYqZdW">
    Dialog with Popover Demo</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Let me explain what's going on: In general, the position of a `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` element in the DOM doesn't
affect its visibility. When you show the popover, the browser adds it to the top layer and it appears on top of all other
content. But only because you can see something doesn't mean you can interact with it. The answer can be found in the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/showModal"
      }}>{`description of the showModal() method`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The showModal() method of the HTMLDialogElement interface displays the dialog as a modal `}{`[...]`}{`. Interaction outside the
dialog is blocked and `}<strong>{`the content outside it is rendered inert`}</strong>{`.`}</p>
    </blockquote>
    <p>{`This means, if you place the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` element for the snackbar outside of the dialog, then it's
rendered inert. All user input events for the element and its descendants are ignored. So what can you
do? Simply place the popover element inside of the dialog element. Now the browser considers it part of the dialog's content
and won't render it inert.`}</p>
    <h3>{`Always close manual popovers nested inside dialogs`}</h3>
    <p>{`One last observation about manual popovers nested inside a modal dialog. If you close the dialog, make sure to also close
the popover with the `}<InlineCode mdxType="InlineCode">{`hidePopover()`}</InlineCode>{` method. Otherwise, the popover might not be visible, but would
still be open and remain in the top layer.`}</p>
    <p>{`Now, when you open the dialog again, it will be placed above the popover inside the top layer. So even if you try to open
the popover again with the `}<InlineCode mdxType="InlineCode">{`showPopover()`}</InlineCode>{` method, nothing will happen! The popover is already open
and placed beneath the modal dialog in the top layer. What a mess!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`The native `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` element and the `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute are very
powerful and convenient features. But there are some pitfalls. I hope my learnings can help you to make great use of
these awesome features in your own projects. Happy coding! 😊`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      